import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopList } from '../components/toplist';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <p>{`BetCasino.se rekommendera svenska nätcasino som är säkra att spela på och som tar hand om insättningar och uttag på ett respektfullt sätt. Du kan läsa runt på vår CasinoPortal i våra olika artiklar och nyheter om Casino samt ta del av de Casino erbjudande som vi listar.`}</p>
    <TopList mdxType="TopList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      